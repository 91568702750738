import {Injectable} from '@angular/core';
import {CountryEnum} from 'api/entities';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {DeviceActivationEntity} from '../../shared/entities/devices/device-activation.entity';
import {AlcedoUserLanguages} from '../../shared/properties/user/user-languages.enum';
import {UserLocalesLabels} from '../../shared/properties/user/user-locales-labels.constant';

export const DEFAULT_COUNTRY = CountryEnum.CH;

@Injectable({providedIn: 'root'})
export class UserLocaleService {
  constructor(private deviceActivationEntity: DeviceActivationEntity) {}

  getCountry(): Observable<string> {
    return new Observable(observer => {
      if (!navigator.geolocation) {
        observer.error({message: 'Geolocation is not supported by your browser.'});
        observer.complete();
      } else {
        navigator.geolocation.getCurrentPosition(
          position => {
            observer.next(position);
            observer.complete();
          },
          positionError => {
            observer.error(positionError);
            observer.complete();
          }
        );
      }
    }).pipe(
      switchMap((position: GeolocationPosition) => {
        if (position) {
          const lat = +position.coords.latitude.toFixed(6);
          const lng = +position.coords.longitude.toFixed(6);
          return this.deviceActivationEntity.getAddressFromGPS(`${lat},${lng}`);
        } else {
          return throwError(() => new Error('Geolocation position could not be determined.'));
        }
      }),
      switchMap(response => {
        if (response.results && response.results.length) {
          const country = response.results[0].address_components.find(address_component => address_component.types.some(type => type === 'country'));
          if (country && country.short_name) {
            return of(country.short_name);
          } else {
            return throwError(() => new Error('Geolocation position could not be determined.'));
          }
        }
        return throwError(() => new Error('Geolocation position could not be determined.'));
      }),
      catchError(error => {
        console.error(error.message);
        return of(DEFAULT_COUNTRY);
      })
    );
  }

  static getLanguage(locale: string): string {
    const language = locale.split('-')[0].toLowerCase();
    return Object.values(AlcedoUserLanguages).includes(language as AlcedoUserLanguages) ? language : AlcedoUserLanguages.ENGLISH;
  }

  static detectUserLocale(locale: string): string {
    // Find supported locale based on language or locale.
    if (UserLocalesLabels[locale]) {
      return locale;
    }

    // Find supported locale based on language if the given locale isn't supported.
    const localeKeys = Object.keys(UserLocalesLabels);
    const language = UserLocaleService.getLanguage(locale);
    for (const localeKey of localeKeys) {
      if (localeKey.indexOf(language) !== -1) {
        return localeKey;
      }
    }

    return null;
  }
}
